import Lottie from 'lottie-react';
import fotball from '../res/fotball.json';

function SectionOne() {

    return ( 
        <div className="section-content">

            <div className="our-history">
            <Lottie animationData={fotball} className="fotball" />

                <div className="our-history-content">
                <p>Verdiverkstedet er et moderne medieselskap med kreatører som elsker å underholde, informere og engasjere. Vi er lidenskapelige historiefortellere som bruker de medier og plattformer som gir historien best muligheter. Vår spisskompetanse er animasjon enten det er 2D eller 3D. Vi lager serier, explainere, reklamefilmer og dokumentarer.</p>
                </div>

            </div>

        </div>

    );
  }

export default SectionOne;