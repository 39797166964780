import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Fade() {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".fade"),
      {
        opacity: 0,
      },
      {
        opacity:0.5,
        duration: 0.5,
        scrollTrigger: {
            trigger: element.querySelector(".fade-trigger"),
            start: "bottom bottom",
            end: "bottom top",
            scrub: true
          }
      }
    );
  }, []);

  return (
      <div ref={ref}>
        <div className="fade">
        </div>

        <div className="fade-trigger">
        </div>
    </div>
  );
}

export default Fade;