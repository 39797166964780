import SectionOne from './sectionOne';

function SectionGrid() {

    return ( 
        <section className="section-grid">
            < SectionOne />
        </section>
    );
  }

export default SectionGrid;