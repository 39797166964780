import './App.css';

import Hero from './Components/hero.js';
import Menu from './Components/menu.js';
import Footer from './Components/footer.js';
import SectionGrid from './Components/sectionGrid.js';
import Fade from './Components/fade.js';


function App() {


  return (
    <div className="App">
      
      < Menu />
      < Hero />
      < Fade />
      < SectionGrid />
      < Footer />

    </div>
  );
}

export default App;
