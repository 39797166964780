import Lottie from 'lottie-react';

import heroAnim from '../res/hero.json';


function Hero() {



    return ( 
        <section className="hero">

        <Lottie animationData={heroAnim} className="hero-anim" />
        
        </section>

    );
  }

export default Hero;