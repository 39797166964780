import ig from '../res/ig.png';

function Menu() {

    return ( 
        <section className="menu">
            <a href="mailto:joakim@verdiverkstedet.com" className="contact-btn">Kontakt oss</a>
            <a href="https://www.instagram.com/verdiverkstedet" className="ig" rel="noreferrer" target="_blank"><img src={ig} alt="instagram" /> </a>
        </section>
    );
  }

export default Menu;